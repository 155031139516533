import "./App.css";
import React, { useEffect } from "react";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Home from "./pages/home/Home";
import Kunye from "./pages/kunye/KunyePage";
import AnimatedText from "../src/components/animatedText/AnimatedText";
// import Carousel from "./components/carousel/carousel";
import AdsLeft from "../src/components/ads/AdsLeft";
import AdsRight from "../src/components/ads/AdsRights";
import Footer from "./components/footer/Footer";
import TopButton from "./components/topButton/TopButton";
import ContentPage from "./pages/content/ContentPage";
import ContactPage from "./pages/contact/ContactPage";
import About from "./pages/about/About";
import ProfilePage from "./pages/profile/ProfilePage";
// import MagazinePage from "./pages/MagazinePage/MagazinePage";
import SearchPage from "./pages/search/SearchPage";
import AdsMobile from "./components/ads/AdsMobile";
import AdsTablet from "./components/ads/adsTablet.jsx";
import BankCategory from "./pages/category/BankCategory";
import AtmCategory from "./pages/category/AtmCategory";
import MobileCategory from "./pages/category/MobileWebCategory";
import CommerceCategory from "./pages/category/CommerceCategory";
import DailyCategory from "./pages/category/DailyCategory";
// import MagazineContentPage from "./pages/MagazinePage/MagazineContentPage";
import VideoCategory from "./pages/category/VideoCategory";
import AlbumCategory from "./pages/category/AlbumCategory";
import VideoContentPage from "./pages/content/VideoContentPage";
import AlbumContentPage1 from "./pages/photoAlbum/AlbumContentPage1";
import AlbumContentPage2 from "./pages/photoAlbum/AlbumContentPage2";
import AlbumContentPage3 from "./pages/photoAlbum/AlbumContentPage3";
import AlbumContentPage4 from "./pages/photoAlbum/AlbumContentPage4";
import AlbumContentPage5 from "./pages/photoAlbum/AlbumContentPage5";
import FintekCategory from "./pages/category/FintekCategory";
import PrivacyPolicy from "./pages/policy/PrivacyPolicy.jsx";
import ClarificationText from "./pages/policy/ClarificationText.jsx";
import ApprovedPage from "./pages/approval/ApprovedPage.jsx";
import DeclinedPage from "./pages/approval/DeclinedPage.jsx";
import TagsPage from "./pages/category/TagsPage.jsx";
import PersonalData from "./pages/policy/PersonalData.jsx";
import AlbumContentPage6 from "./pages/photoAlbum/AlbumContentPage6.jsx";
import TepeAdMobile from "./components/navbar/TepeAdMobile.jsx";
// import AdsDownPopup from "./components/ads/adsDownPopup";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  return (
    <div className="App">
      <Router>
      <ScrollToTop />
        <AnimatedText />
        <Navbar />
        {/* <TepeAdMobile/> */}
        <AdsMobile />
        <AdsTablet/>
        <AdsLeft />
        <AdsRight />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:slug" element={<ContentPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/about" element={<About />} />
          {/* <Route path="/dergi" element={<MagazinePage />} /> */}
          <Route path="/banka" element={<BankCategory />} />
          <Route path="/atm-pos" element={<AtmCategory />} />
          <Route path="/mobil-web" element={<MobileCategory />} />
          <Route path="/e-ticaret" element={<CommerceCategory />} />
          <Route path="/gundem" element={<DailyCategory />} />
          <Route path="/fintek" element={<FintekCategory />} />
          <Route path="/video-galeri" element={<VideoCategory />} />
          <Route path="/video/:id" element={<VideoContentPage />} />
          <Route path="/foto-galeri" element={<AlbumCategory />} /> 
          <Route path="/photo-album-1" element={<AlbumContentPage1 />} />
          <Route path="/photo-album-2" element={<AlbumContentPage2 />} /> 
          <Route path="/photo-album-3" element={<AlbumContentPage3 />} /> 
          <Route path="/photo-album-4" element={<AlbumContentPage4 />} />
          <Route path="/photo-album-5" element={<AlbumContentPage5 />} />
          <Route path="/photo-album-6" element={<AlbumContentPage6 />} />
          <Route path="/Kunye" element={<Kunye />} />
          <Route path="/profile" element={<ProfilePage />} />                  
          <Route path="/search" element={<SearchPage />} />
          {/* <Route path="/magazine-content/:id" element={<MagazineContentPage />}/> */}
          <Route path="/gizlilik-politikasi" element={<PrivacyPolicy />} />
          <Route path="/aydinlatma-metni" element={<ClarificationText />} />
          <Route path="/kvkk" element={<PersonalData />} />
          <Route path="/onayliyorum" element={<ApprovedPage />} />
          <Route path="/onaylamiyorum" element={<DeclinedPage />} />
          <Route path="/tags/:tag" element={<TagsPage />} />
        </Routes>
        {/* <AdsDownPopup /> */}
        <TopButton />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
