import React, { useState, useEffect } from "react";
import "./sdcards.css";
import { newsData } from "./newsData2";
import SliderCard from "../sliderCard/SliderCard";
import Trendnews from "../trendnews/trendnews"

const Card2 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % newsData.length);
      setLoading(true);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const handleMouseEnter = (index) => {
    setCurrentIndex(index);
    setLoading(true);
  };

  const handleImageLoad = () => {
    setLoading(false);
  };

  const currentNews = newsData[currentIndex];

  return (
    <>
      <div id="sdcrs" className="main-card">
        <div className="image-container">
          {loading && <div className="loading">Yükleniyor...</div>}
          <a href={currentNews?.link}>
          <img
            src={currentNews.image}
            alt="Main"
            onLoad={handleImageLoad}
            style={{ display: loading ? "none" : "block" }}
          /></a>
          <div className="category">
          <a href="/foto-galeri" target="_blank" className="category-btn">
                  FOTO GALERİ
                </a>
          </div>
          <h2 className="text-left">{currentNews.title}</h2>
          {/* <p>
            {currentNews.date}
             
          </p> */}
        </div>
        <div className="sub-cards mt-3">
          {newsData.map((news, index) => (
            <a href={news.link}
            target="_blank"
              key={index}
              className={`sub-card ${index === currentIndex ? "active" : ""}`}
              onMouseEnter={() => handleMouseEnter(index)}
            >
              <img src={news.image} alt="Sub" />
              <div className="sub-card-text">
                <h3 style={{ textAlign: "left" }}>{news.title}</h3>
                {/* <p style={{ textAlign: "left", fontSize: 12 }}>
                  {news.date} 
                  
                </p> */}
              </div>
            </a>
          ))}
        </div>
      </div>
    </>
  );
};

export default Card2;
