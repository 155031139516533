import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./smallcards.css";
import TitleBar from "../titleBar/TitleBar";

const API_BASE_URL = "https://api.psmmag.com";

const NewsGrid = () => {
  const [newsItems, setNewsItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}?limit=18`);
        const data = response.data;

        // console.log("API Response Data:", data);

        if (Array.isArray(data)) {
          const hotNewsItems = data
            .filter((item) => !item.isDraft)
            .slice(0, 18);
          setNewsItems(hotNewsItems);
        } else if (data && data.news) {
          const hotNewsItems = data.news
            .filter((item) => !item.isDraft)
            .slice(0, 18);
          setNewsItems(hotNewsItems);
        } else {
          console.error("API yanıtı beklenen formatta değil:", data);
        }
      } catch (error) {
        console.error("Haber verileri çekilirken hata oluştu:", error);
      }
    };

    fetchNews();
  }, []);

  const handleNewsClick = async (newsId, slug, event) => {
    event.preventDefault();
    try {
      await axios.put(`${API_BASE_URL}/${newsId}/increment-view`);
      navigate(`/${slug}`);
    } catch (error) {
      console.error("Görüntülenme sayısı artırılırken hata oluştu:", error);
      navigate(`/${slug}`);
    }
  };

  return (
    <div className="news-grid-container">
      <TitleBar title="Sıcak Haberler" />
      <div className="news-grid mt-4">
        {newsItems.map((item) => (
          <div
            key={item._id}
            className="news-item cursor-pointer"
            onClick={(e) => handleNewsClick(item._id, item.slug, e)}
          >
            <img src={item.image} alt={item.title} />
            <div className="news-content">
              <span style={{ marginTop: "-20px" }} className="category">
                {item.categories.length > 0 ? item.categories[0] : ""}
              </span>
              <h3 className="title">{item.title}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsGrid;