import album6cover from "../../pages/photoAlbum/album6/album6cover.jpg"

export const newsData = [
  {
    title: "PSM AWARDS’24 “Gelecek Burada”",
    date: "18 Aralık 2024",
    author: "Ahmet Yılmaz",
    comments: 2,
    category: ["FOTO GALERİ"],
    image:
      album6cover,
    link: "/photo-album-6",
  },
  {
    title: "WSpark’23: Cumhuriyetin 100. yılında 100 kıvılcım onur listesinde",
    date: "26 Ocak 2024",
    author: "Ahmet Yılmaz",
    comments: 2,
    category: ["FOTO GALERİ"],
    image:
      "https://www.psmmag.com/wp-content/uploads/2024/01/0M0A0444-740x420.jpg",
    link: "/photo-album-1",
  },
  {
    title: "PSM AWARDS WINNER 2023",
    date: "21 Aralık 2023",
    author: "Mehmet Demir",
    comments: 4,
    category: ["FOTO GALERİ"],
    image:
      "https://www.psmmag.com/wp-content/uploads/2023/12/GRUP_FOTO_1-740x420.jpg",
      link: "/photo-album-2",
  },
  {
    title: "SMART-i AWARDS’23",
    date: "28 Eylül 2023",
    author: "Ayşe Kaya",
    comments: 3,
    category: ["FOTO GALERİ"],
    image:
      "https://www.psmmag.com/wp-content/uploads/2023/09/0M0A0838-740x420.jpg",
      link: "/photo-album-3",
  },
  // {
  //   title: "Kategori Toplu Fotoğraflar",
  //   date: "23 Aralık 2022",
  //   author: "Fatma Çelik",
  //   comments: 1,
  //   category: ["FOTO GALERİ"],
  //   image:
  //     "https://www.psmmag.com/wp-content/uploads/2022/12/PSM-AWARDS22-740x420.jpg",
  //     link: "/photo-album-4",
  // },
  // {
  //   title: "PSM AWARDS’22 Panel: Dijital Tsunami",
  //   date: "23 Aralık 2022",
  //   author: "Hakan Uysal",
  //   comments: 2,
  //   category: ["FOTO GALERİ"],
  //   image:
  //     "https://www.psmmag.com/wp-content/uploads/2022/12/psm-awards-2022-740x420.jpg",
  //     link: "/photo-album-5",
  // },
];
