import React, { useEffect, useState } from "react";
import axios from "axios";
import "./trendnews.css";
import qnb from "./300x250.jpg"; // Bu dosya kullanılmıyorsa kaldırılabilir.
import ads2 from "./gazze.jpg"; // Bu dosya kullanılmıyorsa kaldırılabilir.
import TitleBar from "../titleBar/TitleBar";

const AdContainer = ({ ad }) => {
  const adRef = React.useRef(null);

  const handleAdClick = async (ad) => {
    if (ad && ad._id) {
      try {
        await axios.post(`https://api.psmmag.com/api/ads/${ad._id}/hit`);
        window.open(ad.link, '_blank');
      } catch (error) {
        console.error("Error incrementing hit count:", error);
      }
    }
  };

  React.useEffect(() => {
    adRef.current.innerHTML = ""; // Önceki içeriği temizle

    if (
      ad.imageURL.includes("doubleclick.net") ||
      ad.imageURL.includes("sadbundle")
    ) {
      const iframe = document.createElement("iframe");
      iframe.src = ad.imageURL;
      iframe.width = "300";
      iframe.height = "300";
      iframe.frameBorder = "0";
      iframe.scrolling = "no";
      iframe.style = "border: 0px; vertical-align: bottom; margin: 0 auto;";
      if (ad.imageURL.includes("sadbundle")) {
        iframe.allowFullscreen = true;
      }
      adRef.current.appendChild(iframe);
    } else {
      const img = document.createElement("img");
      img.src = ad.imageURL;
      img.alt = ad.title;
      img.className = "my-4";
      img.style =
        "margin: 0 auto; width: 300px; height: 300px; object-fit: contain;";
      adRef.current.appendChild(img);
    }
  }, [ad]);

  return (
    <div onClick={() => handleAdClick(ad)} style={{ cursor: "pointer" }}>
      <div ref={adRef} className="mb-2" style={{ margin: "0 auto" }}>
        {/* Reklam içeriği buraya dinamik olarak eklenecek */}
      </div>
    </div>
  );
};

const Trending = () => {
  const [ads, setAds] = useState([]);
  const [popularNews, setPopularNews] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.psmmag.com/api/ads")
      .then((response) => {
        const filteredAds = response.data.filter(
          (ad) => ad.title.includes("Sidemenü Reklam") && ad.isActive === true
        );
        setAds(filteredAds);
      })
      .catch((error) => {
        console.error("Error fetching ads:", error);
      });

    axios
      .get("https://api.psmmag.com")
      .then((response) => {
        const popularNewsItems = response.data.filter(
          (news) => news.isPopularNews
        );
        setPopularNews(popularNewsItems);
      })
      .catch((error) => {
        console.error("Error fetching popular news:", error);
      });
  }, []);

  return (
    <div className="trends">
      <div className="trending">
        <div className="ads-trends">
          {ads.slice(0, 3).map((ad, index) => (
            <AdContainer key={index} ad={ad} />
          ))}
        </div>
      </div>{" "}
    </div>
  );
};

export default Trending;
