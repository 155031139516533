import React, { useState, useEffect } from "react";
import axios from "axios";
import "./actualNews.css";

const API_BASE_URL = "https://api.psmmag.com";

const CategoryNewsPage = () => {
  const [activeCategory, setActiveCategory] = useState("Gündem");
  const [currentPage, setCurrentPage] = useState(0);
  const [articles, setArticles] = useState([]);
  const itemsPerPage = 18;

  const buttons = [
    { title: "Gündem", link: "/gundem", route: "gundem" },
    { title: "Banka & Kredi Kartları", link: "/banka", route: "banka" },
    { title: "Fintek", link: "/fintek", route: "fintek" },
    { title: "Atm & Pos", link: "/atm-pos", route: "atm-pos" },
  ];

  const categories = [
    { id: "Gündem", name: "Gündem", link: "/gundem" },
    { id: "Banka & Kredi Kartları", name: "Banka", link: "/banka" },
    { id: "Fintek", name: "Fintek", link: "/fintek" },
    { id: "Atm & Pos", name: "ATM & POS", link: "/atm-pos" },
  ];

  useEffect(() => {
    if (activeCategory) {
      const selectedRoute = buttons.find(
        (button) => button.title === activeCategory
      )?.route;
      // console.log('Seçilen route:', selectedRoute)
      axios
        .get(`${API_BASE_URL}/category/${selectedRoute}/?limit=20`)
        .then((response) => {
          // console.log("Tam API Yanıtı:", response.data);
          if (Array.isArray(response.data)) {
            setArticles(response.data); 
          } else if (Array.isArray(response.data.news)) {
            setArticles(response.data.news); 
          } else {
            console.error("API'den dönen veri dizi değil:", response.data);
          }
        })
        .catch((error) => console.error("Haberleri çekerken hata oluştu:", error));


    }
  }, [activeCategory]);

  const handleCategoryClick = (categoryId) => {
    setActiveCategory(categoryId);
    setCurrentPage(0);
  };

  const startIndex = currentPage * itemsPerPage;
  const currentArticles = articles.slice(
    startIndex,
    startIndex + itemsPerPage
  );
  // console.log("API'den Gelen Haberler:", articles);

  // console.log("Görüntülenecek Haberler:", currentArticles);


  return (
    <div className="actualNews">
      <div className="container p-1">
        <div
          className="btn-group d-flex btnTop border-blue-800"
          role="group"
          aria-label="Basic example"
        >
          {categories.map((category) => (
            <button
              key={category.id}
              type="button"
              className={`btn btnTitle btn-light ${category.id === activeCategory ? "active" : ""
                }`}
              onClick={() => handleCategoryClick(category.id)}
            >
              {category.name}
            </button>
          ))}
        </div>
        <div className="mt-2">
          <div className="categories-news-container grid grid-cols-3 gap-3">
            {currentArticles.map((article) => (
              <div key={article._id} className="lg:col-md-4 mt-1">
                <div className="card d-flex flex-row mb-1">
                  <div
                    className="cardImgLink col-6 cursor-pointer"
                    onClick={() => (window.location.href = `/${article.slug}`)}
                  >
                    <img
                      src={article.image}
                      className="card-img-top w-100 object-cover"
                      alt=""
                    />
                  </div>
                  <div className="card-body p-0 col-6">
                    <div className="m-0 px-2 w-100 h-100 d-flex flex-col justify-center items-center font-medium cursor-pointer actual-news-title">
                      {article.title}
                      <span className="date w-100 text-right">
                        {article.date}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            
          </div>
          <div className="mt-4 text-center">
            <a
              href={categories.find((category) => category.id === activeCategory)?.link}
              className="btn btn-secondary"
            >
              Daha Fazla
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryNewsPage;
