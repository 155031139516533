import React from "react";
import TitleBar from "../titleBar/TitleBar";
import Trending from "../trendnews/trendnews";
import MenuPosts from "./MenuPosts";
import CategoryLinks from "./CategoryLinks";
import CurrencyTable from "./CurrencyTable";

const SideMenu = () => {
  return (
    <div className=" col-span-1 mx-auto">
      {/* <h1 className="text-2xl font-bold text-left">Trendler</h1> */}
      {/* <TitleBar title="Trendler" /> */}
      {/* menu posts here */}
      {/* <MenuPosts /> */}
      {/* category links here */}

      
      <div className="">
        <TitleBar title="Kategoriler" />
        <CategoryLinks />
      </div>

<div className="mt-4">
        <Trending/>
      </div>

      <div className="mt-4 pt-4">
        <CurrencyTable />
      </div>
      
    </div>
  );
};

export default SideMenu;
